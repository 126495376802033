<template>
	<section>
		<h2>จัดการข้อมูลการบริการ</h2>

		<div class="folder">
			<div class="title">
				<div class="tab">
					<button class="btn-category active">การบริการ</button>
				</div>
				<div class="operation">
					<button @click="exportExcel()">
						<b-icon icon="download"></b-icon>
					</button>
				</div>	
			</div>

			<div class="text-center mb-3">
				<button class="btn btn-main btn-add-new" @click="openServiceForm(null)">เพิ่มการบริการ</button>
			</div>
			
			<seller-product-manage-filter class="mb-3" :optionFilter="optionFilter" @changeFilter="changeFilter($event)"></seller-product-manage-filter>

			<template v-if="isResultReady">
				<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
					<table>
						<thead>
							<tr>
								<th class="action-colummn"></th>
								<th>ประเภท</th>
								<th class="text-end">ค่าบริการ</th>
								<th>เงื่อนไข</th>
								<th class="text-center">รายการสินค้า</th>
								<th class="action-colummn"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in resultList" :key="index">
								<td class="text-center">
									<button @click="openServiceForm(item)" class="btn btn-sm square btn-warning">แก้ไข</button>
								</td>
								<td>{{ item.name }}</td>
								<td class="text-end">{{ item.amount }}</td>
								<td >
									<span>{{ item.description }}</span>
								</td>
								<td class="text-center">
									<button class="btn btn-sm square btn-outline-danger" @click="openIntegratedProduct(item)">สินค้าร่วมรายการ ({{ item.productIntegrated.length }})</button>
								</td>
								<td class="text-center">
									<button @click="deleteService(item)" class="btn btn-sm square btn-danger">ลบ</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>

			<div class="no-data" v-if="isResultReady && resultList.length === 0">
				<b-icon icon="box-seam"></b-icon>
				<span>ไม่มีรายการบริการ</span>
			</div>
		</div>

		<modal-service
            @close="closeServiceForm($event)"
            :editData="selectedService"
			:optionType="optionService"
            :isDisplay="isOpenServiceForm">
        </modal-service>

		<modal-integrated-product
            @close="closeIntegratedProduct()"
			@reloadContent="reloadContent($event)"
            :title="serviceForIntegration.title"
			:description="serviceForIntegration.description"
			:products="serviceForIntegration.products"
			:keyForIntegration="serviceForIntegration.id"
			typeForIntegration="service"
            :isDisplay="isOpenIntegratedProduct">
        </modal-integrated-product>
	</section>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';
import SellerProductManageFilter from '@/components/seller/stocks/ProductManageFilter';
import ModalService from '@/components/seller/stocks/ModalService';
import ModalIntegratedProduct from '@/components/seller/stocks/ModalIntegratedProduct';
import MasterService from '@/services/masterService';
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';

export default {
    mixins: [ MixinDragScroll ],
	components: {
		SellerProductManageFilter,
		ModalService,
		ModalIntegratedProduct
	},
	data() {
		return {
			isOpenServiceForm: false,
			isOpenIntegratedProduct: false,
			selectedType: 'all',
			selectedService: null,
			serviceForIntegration: {
				id: 0,
				title: '',
				description: '',
				products: null,
				data: null
			},
			optionFilter: [],
			optionService: [],
			isResultReady: false,
			resultList: []
		}
	},
	mounted() {
		this.initServiceList();
		this.getStockService();
	},
	methods: {
		openServiceForm(service) {
			this.selectedService = service;
            this.isOpenServiceForm = true;
        },
        closeServiceForm(data) {
            this.isOpenServiceForm = false;

            if (data.isReload) {
                this.getStockService();
            }
        },
		openIntegratedProduct(service) {
			this.setupServiceIntegration(service);

			this.isOpenIntegratedProduct = true;
		},
		setupServiceIntegration(service) {
			this.serviceForIntegration = {
				id: service.id,
				title: service.name,
				description: service.description,
				products: service.productIntegrated,
				data: service
			};
		},
		closeIntegratedProduct() {
			this.isOpenIntegratedProduct = false;
		},
		async initServiceList() {
			const result = await MasterService.masterGetServiceList(this.selectedCategory);
			const options = [];

			options.unshift({
				id: 'all',
				name: 'แสดงทั้งหมด'
			});

            result.data.forEach((item) => {
                options.push({
                    id: item.id,
                    name: item.name
                });
            });

			this.optionFilter = options;
			this.optionService = options.filter(x => x.id !== 'all');
		},
		async getStockService() {
			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStockService(this.selectedType === 'all' ? '' : this.selectedType);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.isResultReady = true;
			this.resultList = result.data.resultList;

            loader.hide();
		},
		async reloadContent(callback) {
			await this.getStockService();
			const currentService = this.resultList.find(x => x.id === this.serviceForIntegration.id);
			this.setupServiceIntegration(currentService);
			callback();
		},
		changeFilter(type) {
			if (type) {
				this.selectedType = type;

				this.getStockService();
			}
		},
		deleteService(item) {
			this.$swal(
                Helper.confirmAlert('ยืนยันการลบข้อมูลการบริการ', 'โปรดยืนยันว่าท่านต้องการลบข้อมูลการบริการ "' + item.name + '" ออกจากรายการ')
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    let loader = this.$modalLoader.render();
					
					await SellerService.deleteService(item.id);
					this.getStockService();

					loader.hide();
                }
            });
		},
		async exportExcel() {
            let loader = this.$modalLoader.render();
			const result = await SellerService.exportProductIntegration('service');
			loader.hide();

			if (result.data?.success) {
                Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/ProductManage.scss';
</style>