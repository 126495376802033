<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>กำหนดการบริการ</span>
                        </h4>

                        <button class="btn-close" @click="hide(false)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form">
                            <div class="row">
                                <div class="col col-12">
                                    <div class="input-field">
                                        <label>ประเภทการบริการ</label>
                                        <dropdown
                                            v-model="form.serviceType"
                                            placeholder="เลือกประเภทการบริการ"
                                            :disabled="editData ? true : false"
                                            :classes="{
                                                input: [
                                                    'square',
                                                    'input-option'
                                                ]
                                            }"
                                            :options="optionType">
                                        </dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-12 col-sm-6">
                                    <div class="input-field">
                                        <label>กำหนดค่าบริการ</label>
                                        <div class="input-unit">
                                            <input type="tel"
                                                @keypress="$AppUtility.inputOnlyNumber($event)"
                                                v-model="form.amount"
                                                placeholder="สามารถใส่ 0 หากเป็นบริการฟรี"
                                                class="square"
                                                :class="{ 'invalid': error.amount }" />
                                            <span>บ.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-12">
                                    <div class="input-field">
                                        <label>เงื่อนไขและคำบรรยาย</label>
                                        <input type="text"
                                            v-model="form.description"
                                            maxlength="500"
                                            placeholder="บรรยายเงื่อนไข"
                                            class="square"
                                            :class="{ 'invalid': error.description }" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-1" v-if="form.serviceType">
                            <button class="btn btn-main btn-submit square" @click="submitService()">บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>

            <modal-save-product-loader
                :isDisplay="isLoadSaveProduct"
                :savedProduct="savedProduct"
                :totalProduct="totalProduct">
            </modal-save-product-loader>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import Helper from '@/services/helper';
import ModalSaveProductLoader from '@/components/seller/stocks/ModalSaveProductLoader';
import SellerService from '@/services/sellerService';

export default {
    components: { ModalSaveProductLoader },
    data() {
		return {
            calendarTH: th,
            isActive: false,
            form: null,
            error: null,
            isLoadSaveProduct: false,
            savedProduct: 0,
            totalProduct: 0
		};
	},
	props: {
		isDisplay: Boolean,
        editData: Object,
        optionType: Array
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.form = this.initForm();
                this.error = this.initError();

                if (this.editData) {
                    this.savedProduct = 0;
                    this.totalProduct = this.editData.productIntegrated.length;
                }
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
            return {
                id: this.editData ? this.editData.id : '',
                serviceType: this.editData ? this.editData.alias : '',
                amount: this.editData ? this.editData.amount : '',
                description: this.editData ? this.editData.description : ''
            };
        },
        initError() {
            return {
                serviceType: false,
                amount: false,
                description: false
            };
        },
        async submitService() {
            if (this.validate()) {
                this.isLoadSaveProduct = true;
                const result = await SellerService.saveService(this.form);

                if (this.editData) {
                    this.savedProduct = 0;

                    for (let sellerProductId of this.editData.productIntegrated) {
                        this.savedProduct++;
                        await SellerService.updateServiceProduct(sellerProductId);
                    }
                }
                
                this.isLoadSaveProduct = false;

                if (result.data?.success) {
					this.hide(true);
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถบันทึกข้อมูลการบริการได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.serviceType) {
                this.error.serviceType = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.amount) {
                this.error.amount = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.description) {
                this.error.description = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามารถบันทึกข้อมูลการบริการได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/ModalStock.scss';
</style>